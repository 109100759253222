$secondary: #b39d5d;
$primary: #101f2e;
$light: #d3c8a3;
$black: #191d21;
$muted: #343a40;
$info: #adb5bd;

.custom-small-text {
  font-size: 12px;
}

.custom-medium-text {
  font-size: 18px;
}

.swiper-custom-pagination .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.swiper-custom-pagination .swiper-pagination-bullet-active {
  background-color: #101f2e;
}

.swiper-custom-pagination-latest .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.swiper-custom-pagination-latest .swiper-pagination-bullet-active {
  background-color: #101f2e;
}

.swiper-custom-pagination-agents .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.swiper-custom-pagination-agents .swiper-pagination-bullet-active {
  background-color: #101f2e;
}

.swiper-custom-pagination-related .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.swiper-custom-pagination-related .swiper-pagination-bullet-active {
  background-color: #101f2e;
}

.swiper-custom-pagination-about .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.swiper-custom-pagination-about .swiper-pagination-bullet-active {
  background-color: #101f2e;
}


.gallery-custom .image-gallery-slides,
.gallery-custom .image-gallery-thumbnail .image-gallery-thumbnail-inner,
.image-gallery-thumbnail {
  border-radius: 8px;
  border-color: transparent;
  border: 0;
}

.gallery-custom  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus,
.gallery-custom  .image-gallery-thumbnail:hover {
  border-radius: 12px;
  border-color: transparent;
  border: 0;
}

.image-gallery-thumbnail-image {
  border-radius: 8px;
}

.swiper-slide {
  height: auto !important;
}

.swiper-wrapper {
  height: 100%;
}

@import "/node_modules/bootstrap/scss/bootstrap.scss";
